import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Health from "./pages/Health";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import Digital from "./pages/Digital";

function App() {
  // useEffect(() => {
  //   const disableContextMenu = (e: MouseEvent) => {
  //     e.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", disableContextMenu);
  //   return () => {
  //     document.removeEventListener("contextmenu", disableContextMenu);
  //   };
  // }, []);
  return (
    <BrowserRouter>
      <Toaster position="top-right" />
      <Routes>
        <Route path="/" element={<Health />} />
        <Route path="/digital_inconstruction" element={<Digital />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
