import FormContact from "../components/form/formContact";
import Zoom from "react-medium-image-zoom";
import NavbarFrancaHealth from "../components/navbar/navabarFranca";
import SliderBrands from "../components/sliderBrands/sliderBrands";
import "../styles/health.css";
import "../styles/general.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Footer from "../components/footer/footer";
import SpinningText from "../components/spinningText/spinningText";
import ModalSlider from "../components/modalSliters/modalSlider";
import { sliderHealth } from "../constants/sliderHealth";

export default function Health() {
  const handleScrollToSectionManifest = () => {
    const targetSection = document.getElementById("sectionManifest");
    if (targetSection) {
      const yOffset = -75; // Ajuste de desplazamiento
      const y =
        targetSection.getBoundingClientRect().top + window.scrollY + yOffset; // Usando scrollY

      // Realizar el scroll suave hacia la posición calculada
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    } else {
      console.error("Element not found: sectionContact");
    }
  };

  return (
    <>
      <div className="healthFranca">
        <div className="healthLateralAgencyInformation">
          <div>
            <p className="akz">A FAMILIAR FACE</p>
            <p className="freight">in the digital world</p>
          </div>
          <div>
            <p className="akz">BOUNDLESS CREATIVITY,</p>
            <p className="freight">endless possibilities.</p>
          </div>
          <div>
            <p className="akz">CREATING UNFORGETTABLE</p>
            <p className="freight">brand experiences</p>
          </div>
          <div>
            <p className="akz">CRAFTING</p>
            <p className="freight">visual stories</p>
          </div>
          <div>
            <p className="akz">CONNECTING BRANDS</p>
            <p className="freight">and voices</p>
          </div>
          <div>
            <p className="akz">A FAMILIAR FACE</p>
            <p className="freight">in the digital world</p>
          </div>
          <div>
            <p className="akz">BOUNDLESS CREATIVITY,</p>
            <p className="freight">endless possibilities.</p>
          </div>
          <div>
            <p className="akz">CREATING UNFORGETTABLE</p>
            <p className="freight">brand experiences</p>
          </div>
          <div>
            <p className="akz">CRAFTING</p>
            <p className="freight">visual stories</p>
          </div>
          <div>
            <p className="akz">CONNECTING BRANDS</p>
            <p className="freight">and voices</p>
          </div>
          <div>
            <p className="akz">A FAMILIAR FACE</p>
            <p className="freight">in the digital world</p>
          </div>
          <div>
            <p className="akz">BOUNDLESS CREATIVITY,</p>
            <p className="freight">endless possibilities.</p>
          </div>
          <div>
            <p className="akz">CREATING UNFORGETTABLE</p>
            <p className="freight">brand experiences</p>
          </div>
        </div>
        <div className="healthBody">
          <NavbarFrancaHealth
            logo={
              <img
                src="/assets/Logos/FRANCA_landing_page_logos_health.png"
                alt="Logo health"
                width={150}
                height={80}
              />
            }
          />
          <section className="aboutHealth">
            <div className="aboutHealth_container">
              <img src="/assets/circle-green.png" alt="" />
              <article>
                12 años impulsando la comunicación estratégica, creativa y
                digital en Pharma y Healthcare.
              </article>
              <div className="aboutHealth_container_arrow">
                <div onClick={handleScrollToSectionManifest}>
                  <ArrowDownwardIcon
                    sx={{
                      fontSize: "65px",
                      color: "var(--green-franca-color)",
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="manifestHealth" id="sectionManifest">
            <div className="manifestHealth_image">
              <SpinningText text="creative - digital - audiovisual - experiential - influence -">
                <img
                  src="/assets/FRANCA_landing_page_logos_Isotipof.png"
                  alt=""
                />
              </SpinningText>
            </div>
            <div className="manifestHealth_container">
              <div>
                <h1 className="manifestHealth_verticalTitle">manifesto</h1>
              </div>
              <div className="manifestHealth_paraghrap">
                <p>
                  En
                  <b style={{ marginLeft: "8px" }}>
                    Franca <span className="registered">&#174;</span> Agency
                  </b>
                  , no solo redefinimos lo que significa ser una agencia
                  especializada en Pharma y Healthcare, sino que también
                  impulsamos una nueva forma de hacer que la creatividad sea
                  realmente efectiva.
                  <p>
                    Hemos evolucionado para ofrecer soluciones clave que
                    conectan de manera estratégica a marcas con profesionales de
                    la salud, pacientes y otros actores esenciales en un entorno
                    cada vez más competitivo y ruidoso.
                  </p>
                  <p>
                    Esto va más allá de crear un solo anuncio o campaña. Es un
                    proceso continuo que amplía el alcance de tu mensaje y
                    acorta la distancia entre tu marca y su audiencia. Nos
                    enfocamos en facilitar que profesionales de la salud y
                    pacientes "se encuentren a mitad de camino", creando
                    comunicaciones que no solo se ven, sino que también se
                    comprenden y generan resultados tangibles.
                  </p>
                  <p>
                    Franca
                    <b style={{ marginRight: "2px" }}>
                      <span
                        className="registered"
                        style={{ marginRight: "2px" }}
                      >
                        &#174;
                      </span>
                    </b>
                    Agency no es una agencia más. Somos la agencia independiente
                    más impactante del momento, llegó la hora de conocernos.
                  </p>
                </p>
              </div>
            </div>
          </section>
          <section className="comunicationHealth">
            <div>
              <img src="/assets/start-green.png" alt="" />
              <h1 className="comunicationHealth_title">
                Así potenciamos <br /> tu comunicación
              </h1>
              <h1 className="comunicationHealth_subtitle">
                Comunicació n interna y externa
              </h1>
              <ul>
                <li>Campañas estratégicas para marcas globales</li>
                <li>
                  Campañas en redes sociales enfocadas en Pharma y Healthcare
                </li>
                <li>Campañas de conciencia sobre enfermedades</li>
                <li>
                  Desarrollo de identidades internas y campañas motivacionales
                  para equipos
                </li>
                <li>Creación de concepto para convenciones corporativas</li>
              </ul>
              <h1 className="comunicationHealth_subtitle">
                Posicionamiento de productos y planificació n estratégica
              </h1>
              <ul>
                <li>Estrategias de marketing y comunicación médica</li>
                <li>
                  Implementación de estrategias omnicanal (OCE) y multicanal
                  (MCE)
                </li>
                <li>
                  Posicionamiento de lanzamientos y desarrollo de mensajes clave
                </li>
                <li>
                  Creación de identidades visuales para informes científicos
                </li>
              </ul>
              <h1 className="comunicationHealth_subtitle">
                Herramientas y servicios digitales para potenciar tu marca
              </h1>
              <ul>
                <li>Herramientas de escucha social y análisis de mercado</li>
                <li>Herramientas de monitoreo y alertas en tiempo real</li>
              </ul>
            </div>
          </section>
          <section className="useCase">
            <div className="useCase_Modal">
              {sliderHealth.map((slideData) => {
                return <ModalSlider dataSlider={slideData} />;
              })}
            </div>
            <div className="useCase_block useCase_blockOne">
              <div className="image-container">
                <Zoom classDialog="zoom_useCase">
                  <img
                    src="/assets/CREATIVE/DIGITAL CAMP/ONEPAGE/Franca_DC_2024_caso_digital_camp.png"
                    alt=""
                  />
                </Zoom>
                <div className="hover-text">VER CASO</div>
              </div>

              <div className="image-container">
                <Zoom>
                  <img
                    src="/assets/CREATIVE/CETAPHIL/ONE PAGE/Caso-generación-ceta.jpg"
                    alt=""
                  />
                </Zoom>
                <div className="hover-text">VER CASO</div>
              </div>
            </div>
            <div className="useCase_block useCase_blockTow">
              <div className="image-container">
                <Zoom classDialog="zoom_useCase">
                  <img
                    src="/assets/CREATIVE/FRESENNIUS/ONEPAGE/FK_FRANCA_CAMPAÑA_FK_OP_FINAL_Mesa de trabajo 1.png"
                    alt=""
                  />
                </Zoom>
                <div className="hover-text">VER CASO</div>
              </div>

              <div className="image-container">
                <Zoom>
                  <img
                    src="/assets/CREATIVE/TU SALUD NO ES UN JUEGO/ONEPAGE/tu-salud-no-es-un-juego-ONE-PAGE (1).jpg"
                    alt=""
                  />
                </Zoom>
                <div className="hover-text">VER CASO</div>
              </div>
            </div>
          </section>
          <section className="clientsBrands">
            <img src="/assets/mid-cicle-green.png" alt="" />
            <h1 className="clientsBrands_title">Clientes & marcas</h1>
          </section>
          <section className="brands">
            <div>
              <SliderBrands />
            </div>
          </section>

          <section id="sectionContact" className="contact">
            <h1 className="contact_title">Contacto</h1>
          </section>
          <section className="formContact">
            <div>
              <img
                src={"/assets/Health/LANDING PAGE FRANCA DIGITAL.png"}
                alt="img-formContat"
              />
            </div>
            <div>
              <FormContact />
            </div>
          </section>
          <section className="contactInformation">
            <div>
              <div className="contactInformation_contact">
                <h1 className="contactInformationHealth_verticalTitle">
                  Bogotá
                </h1>
                <div className="contactInformation_description">
                  <p>
                    Calle 98 # 10 46 <br />
                    Oficina 304 / 401 <br />
                    Bogotá, Colombia <br />
                    hola@franca.agency <br />
                    +57 312 466 3076 <br />
                    <br />
                    <p className="contactInformationHealth__location-link ">
                      <a
                        href="https://www.linkedin.com/company/franca-agency/"
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="contactInformation_contact">
                <h1 className="contactInformationHealth_verticalTitle">
                  CDMX{" "}
                </h1>
                <div className="contactInformation_description">
                  <p>
                    Av. Insurgentes Sur 1079, <br />
                    Col del Valle Centro, <br />
                    Benito Juárez, Ciudad de México, <br />
                    C.P. 03100 Oficina: 06-103 DD07 <br />
                    hola@franca.agency <br />
                    +57 312 466 3076 <br />
                    <br />
                    <p className="contactInformationHealth__location-link ">
                      <a
                        href="https://www.linkedin.com/company/franca-agency/"
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}
