import { ModalType } from "../types/ModalTypes";

export const sliderHealth: ModalType[] = [
  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__Cetaphil 01.jpg",
    slide: [
      "/assets/CREATIVE/CETAPHIL/RESPONSIVE/1.jpg",
      "/assets/CREATIVE/CETAPHIL/RESPONSIVE/2.jpg",
      "/assets/CREATIVE/CETAPHIL/RESPONSIVE/3.jpg",
      "/assets/CREATIVE/CETAPHIL/RESPONSIVE/4.jpg",
    ],
  },
  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__Digital camp.jpg",
    slide: [
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/1.png",
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/2.png",
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/3.png",
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/4.png",
    ],
  },

  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__FRESENIUS KABI.jpg",
    slide: [
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/1.png",
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/2.png",
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/3.png",
      "/assets/CREATIVE/FRESENNIUS/RESPONSIVE/4.png",
    ],
  },

  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__tu salud no es un juego .jpg",
    slide: [
      "/assets/CREATIVE/TU SALUD NO ES UN JUEGO/RESPONSIVE/1.jpg",
      "/assets/CREATIVE/TU SALUD NO ES UN JUEGO/RESPONSIVE/2.jpg",
      "/assets/CREATIVE/TU SALUD NO ES UN JUEGO/RESPONSIVE/3.jpg",
    ],
  },
];
