import FormContact from "../components/form/formContact";
import Zoom from "react-medium-image-zoom";
import NavbarFrancaHealth from "../components/navbar/navabarFranca";
import SliderBrands from "../components/sliderBrands/sliderBrands";
import "../styles/general.css";
import "../styles/digital.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Footer from "../components/footer/footer";
import SpinningText from "../components/spinningText/spinningText";
import ModalSlider from "../components/modalSliters/modalSlider";
import { sliderDigital } from "../constants/sliderDigital";

export default function Digital() {
  const handleScrollToSectionManifest = () => {
    const targetSection = document.getElementById("sectionManifest");
    if (targetSection) {
      const yOffset = -75; // Ajuste de desplazamiento
      const y =
        targetSection.getBoundingClientRect().top + window.scrollY + yOffset; // Usando scrollY

      // Realizar el scroll suave hacia la posición calculada
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    } else {
      console.error("Element not found: sectionContact");
    }
  };

  return (
    <>
      <div className="healthFranca">
        <div className="healthLateralAgencyInformation">
          <div>
            <p className="akz">A FAMILIAR FACE</p>
            <p className="freight">in the digital world</p>
          </div>
          <div>
            <p className="akz">BOUNDLESS CREATIVITY,</p>
            <p className="freight">endless possibilities.</p>
          </div>
          <div>
            <p className="akz">CREATING UNFORGETTABLE</p>
            <p className="freight">brand experiences</p>
          </div>
          <div>
            <p className="akz">CRAFTING</p>
            <p className="freight">visual stories</p>
          </div>
          <div>
            <p className="akz">CONNECTING BRANDS</p>
            <p className="freight">and voices</p>
          </div>
          <div>
            <p className="akz">A FAMILIAR FACE</p>
            <p className="freight">in the digital world</p>
          </div>
          <div>
            <p className="akz">BOUNDLESS CREATIVITY,</p>
            <p className="freight">endless possibilities.</p>
          </div>
          <div>
            <p className="akz">CREATING UNFORGETTABLE</p>
            <p className="freight">brand experiences</p>
          </div>
          <div>
            <p className="akz">CRAFTING</p>
            <p className="freight">visual stories</p>
          </div>
          <div>
            <p className="akz">CONNECTING BRANDS</p>
            <p className="freight">and voices</p>
          </div>
          <div>
            <p className="akz">A FAMILIAR FACE</p>
            <p className="freight">in the digital world</p>
          </div>
          <div>
            <p className="akz">BOUNDLESS CREATIVITY,</p>
            <p className="freight">endless possibilities.</p>
          </div>
          <div>
            <p className="akz">CREATING UNFORGETTABLE</p>
            <p className="freight">brand experiences</p>
          </div>
        </div>
        <div className="healthBody">
          <NavbarFrancaHealth
            logo={
              <img
                src="/assets/DigitalAssets/FRANCA_landing_page_logos_digital.png"
                alt="Logo health"
                width={150}
                height={80}
              />
            }
          />
          <section className="aboutHealth">
            <div className="aboutHealth_container">
              <img src="/assets/circle-green.png" alt="" />
              <article>
                Transformamos datos en decisiones, contenido en
                <i style={{ marginRight: "15px" }}>engagement </i> y pauta en
                resultados.
              </article>
              <div className="aboutHealth_container_arrow">
                <div onClick={handleScrollToSectionManifest}>
                  <ArrowDownwardIcon
                    sx={{
                      fontSize: "65px",
                      color: "var(--purple-franca-color)",
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="manifestHealth" id="sectionManifest">
            <div className="manifestHealth_image">
              <SpinningText text="creative - digital - audiovisual - experiential - influence -">
                <img
                  src="/assets/FRANCA_landing_page_logos_Isotipof.png"
                  alt=""
                />
              </SpinningText>
            </div>
            <div className="manifestHealth_container">
              <div>
                <h1 className="manifestHealth_verticalTitle">manifesto</h1>
              </div>
              <div className="manifestHealth_paraghrap">
                <p>
                  En
                  <b style={{ marginLeft: "8px" }}>
                    Franca <span className="registered">&#174;</span> Agency
                  </b>
                  , no solo redefinimos lo que significa ser una agencia
                  especializada en Pharma y Healthcare, sino que también
                  impulsamos una nueva forma de hacer que la creatividad sea
                  realmente efectiva.
                  <p>
                    Hemos evolucionado para ofrecer soluciones clave que
                    conectan de manera estratégica a marcas con profesionales de
                    la salud, pacientes y otros actores esenciales en un entorno
                    cada vez más competitivo y ruidoso.
                  </p>
                  <p>
                    Esto va más allá de crear un solo anuncio o campaña. Es un
                    proceso continuo que amplía el alcance de tu mensaje y
                    acorta la distancia entre tu marca y su audiencia. Nos
                    enfocamos en facilitar que profesionales de la salud y
                    pacientes "se encuentren a mitad de camino", creando
                    comunicaciones que no solo se ven, sino que también se
                    comprenden y generan resultados tangibles.
                  </p>
                  <p>
                    Franca
                    <b style={{ marginRight: "2px" }}>
                      <span
                        className="registered"
                        style={{ marginRight: "2px" }}
                      >
                        &#174;
                      </span>
                    </b>
                    Agency no es una agencia más. Somos la agencia independiente
                    más impactante del momento, llegó la hora de conocernos.
                  </p>
                </p>
              </div>
            </div>
          </section>
          <section className="comunicationHealth">
            <div>
              <img src="/assets/start-green.png" alt="" />
              <h1 className="comunicationHealth_title">
                Así potenciamos <br /> tu comunicación digital
              </h1>
              <h1 className="comunicationHealth_subtitle">
                Contenido que conecta con tu audiencia
              </h1>
              <ul>
                <li>Desarrollo de estrategias de contenido.</li>
                <li>Gestión y optimización de canales digitales.</li>
                <li>
                  Diseño de campañas en nuevos canales y colaboraciones con
                  influenciadores.
                </li>
                <li>
                  Creación de contenido en tiempo real para la cobertura de
                  eventos.
                </li>
                <li>
                  Desarrollo de contenido audiovisual para instagram y tiktok.
                </li>
              </ul>
              <h1 className="comunicationHealth_subtitle">
                Análisis para decisiones basadas en data
              </h1>
              <ul>
                <li>Evaluación de métricas clave y desempeño digital.</li>
                <li>
                  Implementación de herramientas de visualización y monitoreo.
                </li>
                <li>Identificacación de tendencias y alertas de marca.</li>
                <li>Análisis de conversaciones en redes sociales.</li>
                <li>
                  Generación de informes sobre las necesidades del mercado Vs la
                  marca.
                </li>
              </ul>
              <h1 className="comunicationHealth_subtitle">
                Pauta optimizada para resultados efectivos
              </h1>
              <ul>
                <li>Diseño y ejecución de estrategias de pauta digital.</li>
                <li>Optimización de ads mediante campañas de programática.</li>
                <li>Generación de informes detallados Search Query Reports.</li>
              </ul>
            </div>
          </section>
          <section className="useCase">
            <div className="useCase_Modal">
              {sliderDigital.map((slideData) => {
                return <ModalSlider dataSlider={slideData} />;
              })}
            </div>
            <div className="useCase_block useCase_blockUnique">
              <div className="image-container">
                <Zoom classDialog="zoom_useCase">
                  <img
                    src="/assets/Captura de pantalla 2024-09-25 a la(s) 11.18.11 a. m..png"
                    alt=""
                  />
                </Zoom>
                <div className="hover-text">VER CASO</div>
              </div>

              <div className="image-container">
                <Zoom>
                  <img src="/assets/DIGITAL/LANDING DIGITAL/CASO CETHAPIL/ONE PAGE/OP1.jpg" alt="" />
                </Zoom>
                <div className="hover-text">VER CASO</div>
              </div>
              <div className="image-container">
                <Zoom classDialog="zoom_useCase">
                  <img
                    src="/assets/DIGITAL/LANDING DIGITAL/FRESSENIUS/ONEPAGE/FR_FK_CASODIGITA1.png"
                    alt=""
                  />
                </Zoom>
                <div className="hover-text">VER CASO</div>
              </div>
            </div>
          </section>
          <section className="clientsBrands">
            <img src="/assets/mid-cicle-green.png" alt="" />
            <h1 className="clientsBrands_title">Clientes & marcas</h1>
          </section>
          <section className="brands">
            <div>
              <SliderBrands />
            </div>
          </section>

          <section id="sectionContact" className="contact">
            <h1 className="contact_title">Contacto</h1>
          </section>
          <section className="formContact">
            <div>
              <img
                src={"/assets/Health/LANDING PAGE FRANCA DIGITAL.png"}
                alt="img-formContat"
              />
            </div>
            <div>
              <FormContact />
            </div>
          </section>
          <section className="contactInformation">
            <div>
              <div className="contactInformation_contact">
                <h1 className="contactInformationHealth_verticalTitle">
                  Bogotá
                </h1>
                <div className="contactInformation_description">
                  <p>
                    Calle 98 # 10 46 <br />
                    Oficina 304 / 401 <br />
                    Bogotá, Colombia <br />
                    hola@franca.agency <br />
                    +57 312 466 3076 <br />
                    <br />
                    <p className="contactInformationHealth__location-link ">
                      <a
                        href="https://www.linkedin.com/company/franca-agency/"
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="contactInformation_contact">
                <h1 className="contactInformationHealth_verticalTitle">
                  CDMX{" "}
                </h1>
                <div className="contactInformation_description">
                  <p>
                    Av. Insurgentes Sur 1079, <br />
                    Col del Valle Centro, <br />
                    Benito Juárez, Ciudad de México, <br />
                    C.P. 03100 Oficina: 06-103 DD07 <br />
                    hola@franca.agency <br />
                    +57 312 466 3076 <br />
                    <br />
                    <p className="contactInformationHealth__location-link ">
                      <a
                        href="https://www.linkedin.com/company/franca-agency/"
                        target="_blank"
                      >
                        Linkedin
                      </a>
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}
