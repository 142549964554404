import { ModalType } from "../types/ModalTypes";

export const sliderDigital: ModalType[] = [
  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__Cetaphil 01.jpg",
    slide: [
      "/assets/DIGITAL/LANDING DIGITAL/CASO CETHAPIL/RESPONSIVE/1.jpg",
      "/assets/DIGITAL/LANDING DIGITAL/CASO CETHAPIL/RESPONSIVE/2.jpg",
      "/assets/DIGITAL/LANDING DIGITAL/CASO CETHAPIL/RESPONSIVE/3.jpg",
    ],
  },
  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__Digital camp.jpg",
    slide: [
      "/assets/DIGITAL/LANDING DIGITAL/CASO CETHAPIL/RESPONSIVE.1.jpg",
      "/assets/DIGITAL/LANDING DIGITAL/CASO CETHAPIL/RESPONSIVE.2.jpg",
      "/assets/DIGITAL/LANDING DIGITAL/CASO CETHAPIL/RESPONSIVE.3.jpg",
    ],
  },

  {
    name: "/assets/CasosHealthMovile/FR_VER CASOS 300PX__FRESENIUS KABI.jpg",
    slide: [
      "/assets/DIGITAL/LANDING DIGITAL/FRESSENIUS/RESPONSIVE/FR_FK_CASODIGITAL_01.jpg",
      "/assets/DIGITAL/LANDING DIGITAL/FRESSENIUS/RESPONSIVE/FR_FK_CASODIGITAL-04.jpg",
      "/assets/DIGITAL/LANDING DIGITAL/FRESSENIUS/RESPONSIVE/FR_FK_CASODIGITAL-05.jpg",
      "/assets/DIGITAL/LANDING DIGITAL/FRESSENIUS/RESPONSIVE/FR_FK_CASODIGITAL-06.jpg",
    ],
  },
];
